import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import AnchorLink from "./anchorLink"

function Post({ post }) {
  const coverImage = getImage(post.frontmatter.coverImage)

  return (
    <div className="col-span-4">
      <AnchorLink
        to={post.fields.slug}
        className="group relative block w-full focus:outline-none"
      >
        {coverImage ? (
          <div className="aspect-h-4 aspect-w-3 rounded-lg">
            <GatsbyImage
              image={coverImage}
              alt=""
              className="w-full rounded-lg object-cover object-center transition mb-8"
            />
          </div>
        ) : null}
        <h3 className="text-2xl font-bold text-gray-900">
          {post.frontmatter.title}
        </h3>
      </AnchorLink>
    </div>
  )
}

function PostList({ posts }) {
  return (
    <div className="relative grid gap-x-4 gap-y-10 grid-cols-4 md:grid-cols-8 lg:gap-x-6 lg:grid-cols-12 mx-auto max-w-7xl">
      {posts.map(post => (
        <Post post={post} key={post.fields.slug} />
      ))}
    </div>
  )
}

export default PostList
